<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <!-- Login v1 -->
      <b-card
        v-if="isLoading"
        class="text-center"
      >
        <b-spinner
          small
          class="mb-1"
        />
        <div>Validation de votre connexion</div>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { initialAbility } from '@/libs/acl/config'
import localstorageService from '@/services/localstorage/localstorage.service'

export default {
  components: {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isLoading: false,
      code: null,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        const code  = to.query.code
        if (code && code !== this.code) {
          this.code = code
          this.initData()
        }
      },
    },
  },
  methods: {
    initData() {
      this.isLoading = true
      this.$store.dispatch('auth/loginOfficial', { code: this.code })
        .then(response => {
          this.isLoading = false
          const { admin: userData } = response.data
          const permissions = [
            ...initialAbility,
            ...(
              (userData.role && userData.role.permissions)
              || []
            ).filter(e => e.subject !== 'all'),

          ]

          localstorageService.setUserData(
            {
              ...userData,
              role: { ...userData.role, permissions },
              ability: permissions,
            },
          )
          const pathToRedirectToAfterLogin = localstorageService.getRouteToRedirectToAfterLogin()
          window.location = pathToRedirectToAfterLogin || '/'
        })
        .catch(error => {
          this.isLoading = false
          // console.error('error::: ', error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.message || 'Email or Password is Invalid',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
